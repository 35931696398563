import { BACKEND_URL, FLASK_HELPER_URL } from '../config';

export const GET_PROJECT_URL = `${BACKEND_URL}/projects`;
export const PROJECT_CREATION_URL = `${BACKEND_URL}/projects/add`;
export const TASK_CREATION_URL = (projectName) =>
  `${BACKEND_URL}/projects/name/${projectName}/tasks`;
export const ASSIGN_EMPLOYEE_PROJECT_URL = (projectName) =>
  `${BACKEND_URL}/projects/name/${projectName}/assignEmployee`;
export const DEASSIGN_EMPLOYEE_PROJECT_URL = (projectName) =>
  `${BACKEND_URL}/projects/name/${projectName}/deAssignEmployee`;
export const GET_TIMESHEET_URL = `${BACKEND_URL}/timesheets`;
export const EMPLOYEE_URL = `${BACKEND_URL}/employee`;
export const CLIENT_URL = `${BACKEND_URL}/clients`;
export const HOLIDAY_URL = `${BACKEND_URL}/holidays`;
export const GET_MONTH_HOLIDAYS_URL = (month, year) =>
  `${BACKEND_URL}/holidays/${year}/${month}`;
export const ADD_HOLIDAY_URL = (month, year) =>
  `${BACKEND_URL}/holidays/${year}/${month}/addDay`;
export const REMOVE_HOLIDAY_URL = (month, year) =>
  `${BACKEND_URL}/holidays/${year}/${month}/removeDay`;
export const RIGHTS_URL = `${BACKEND_URL}/rights`;
export const PROFILES_URL = `${BACKEND_URL}/profiles`;
export const ROLES_URL = `${BACKEND_URL}/roles`;
export const ENTITIES_URL = `${BACKEND_URL}/entities`;
export const DEPARTMENTS_URL = `${BACKEND_URL}/departments`;

// Services
export const ENGAGEMENTS_URL = `${BACKEND_URL}/itgc/engagement`;
export const UNDERSTANDING_URL = `${BACKEND_URL}/itgc/understanding`;
export const APPLICATION_URL = `${BACKEND_URL}/itgc/application`;
export const RCM_URL = `${BACKEND_URL}/itgc/rcm`;
export const DRL_TEMPLATE_URL = `${BACKEND_URL}/itgc/drl-template`;
export const DRL_URL = `${BACKEND_URL}/itgc/drl`;
export const DRL_LOGS_URL = `${BACKEND_URL}/itgc/drl-logs`;
export const WORKFLOW_HANDLER_URL = `${BACKEND_URL}/workflow-handler`;
export const ACTIVE_WORKFLOW_HANDLER_URL = `${BACKEND_URL}/active-workflow/workflow-handler`;
export const DRL_SAMPLES_URL = `${BACKEND_URL}/itgc/drl-document-samples`;
export const SERVICES_URL = `${BACKEND_URL}/itgc/service`;
export const FIELD_REFRENCE_URL = `${BACKEND_URL}/itgc/fieldreference`;
export const ORG_TABLE_URL = `${BACKEND_URL}/engagement/org-table`;
export const TRUST_SERVICE_CRITERIA_URL = `${BACKEND_URL}/engagement/trustServiceCriteria`;
export const TESTING_SHEET_CREATOR_URL = `${BACKEND_URL}/testingSheetCreator`;

// Template Editor
export const SERVICE_URL = `${BACKEND_URL}/itgc/service`;
export const TEMPLATE_URL = `${BACKEND_URL}/itgc/template`;
export const DOCUMENT_URL = `${BACKEND_URL}/itgc/document`;

// Document Manager
export const DOCUMENT_MANAGER_URL = `${BACKEND_URL}/documents`;
export const READ_MSG_FILE_URL = `${FLASK_HELPER_URL}/evidences/file`;

// Policies
export const POLICY_TEMPLATE_URL = `${BACKEND_URL}/policy-template`;
export const GET_ALL_TAGS_URL = `${BACKEND_URL}/policy-template/get-tags`;
export const POLICY_ENGAGEMENT_URL = `${BACKEND_URL}/policy-engagement`;
export const POLICY_URL = `${BACKEND_URL}/policy`;
export const POLICY_MASTER_URL = `${BACKEND_URL}/policy/master`;
export const GENERIC_POLICY_MASTER_URL = `${BACKEND_URL}/policy/master/genericPolicy`;
export const EMAIL_POLICY_MASTER_URL = `${BACKEND_URL}/policy/master/publishEmail`;
export const GAP_TRACKER_URL = `${BACKEND_URL}/gap-tracker`;
export const SCF_CONTROLS_URL = `${BACKEND_URL}/policy/scf/control`;
export const SCF_GOVERNANCE_URL = `${BACKEND_URL}/policy/scf/governance`;
export const OPEN_AI_URL = `${BACKEND_URL}/openai`;
export const PROMPT_URL = `${BACKEND_URL}/prompt`;

//pdf to excel
export const PDF_TO_EXCEL_URL = `${BACKEND_URL}/pdf-to-excel`;
export const SOC_PDF_DOWNLOAD = `${BACKEND_URL}/pdf-to-excel/soc`;
export const SOC_REPORT_SAVE_URL = `${BACKEND_URL}/itgc/soc-report-excel`;

export const SEND_OFFER_LETTER = `${FLASK_HELPER_URL}/send-offer-letter`;
