import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Typography
} from '@mui/material';
import * as XLSX from 'xlsx';

const SalaryStructure = ({ onDataChange, onClear }) => {
  const [data, setData] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);

  useEffect(() => {
    //Clearing Data
    setData([]);
    setUploadedFile(null);
  }, [onClear]);

  const handleInputChange = (id, field, value) => {
    setData((prevData) =>
      prevData.map((row) => (row.id === id ? { ...row, [field]: value } : row))
    );
    onDataChange(data);
  };

  const addRow = () => {
    //Add new row
    setData((prevData) => [
      ...prevData,
      {
        id: prevData.length + 1,
        SalaryHead: '',
        MonthlyINR: '',
        AnnualINR: '',
        Remarks: ''
      }
    ]);
  };

  const deleteLastRow = () => {
    //Delete New Row
    setData((prevData) => prevData.slice(0, -1));
  };

  const handleFileChange = (e) => {
    const fileInput = e.target;
    const file = e.target.files[0];
    if (!file) return;

    setUploadedFile(file);

    const reader = new FileReader();
    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      const workbook = XLSX.read(arrayBuffer, { type: 'array' });

      const sheet = workbook.Sheets[workbook.SheetNames[0]];

      // Parsing Sheet Data
      const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      // Filtering out empty rows
      const filteredData = parsedData.filter((row) => row.length > 0);

      // Updating Data
      setData(
        filteredData.map((row, index) => ({
          id: index + 1,
          SalaryHead: row[0] || '',
          MonthlyINR: row[1] || '',
          AnnualINR: row[2] || '',
          Remarks: row[3] || ''
        }))
      );

      onDataChange(filteredData);
      fileInput.value = '';
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <div
      style={{
        margin: '3rem 1.5rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '1rem'
      }}
    >
      <Typography>Upload Salary Structure</Typography>
      <input type="file" accept=".xlsx" onChange={handleFileChange} />
      {uploadedFile && (
        <Typography style={{ marginTop: '1rem' }}>
          Uploaded File: {uploadedFile.name}
        </Typography>
      )}
      <TableContainer component={Paper}>
        <Table style={{ width: '1000px', border: 'none' }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '25%' }}>Salary Head</TableCell>
              <TableCell style={{ width: '25%' }}>Monthly INR</TableCell>
              <TableCell style={{ width: '25%' }}>Annual INR</TableCell>
              <TableCell style={{ width: '25%' }}>Remarks</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <TextField
                    type="text"
                    value={row.SalaryHead}
                    onChange={(e) =>
                      handleInputChange(row.id, 'SalaryHead', e.target.value)
                    }
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="text"
                    value={row.MonthlyINR}
                    onChange={(e) =>
                      handleInputChange(row.id, 'MonthlyINR', e.target.value)
                    }
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="text"
                    value={row.AnnualINR}
                    onChange={(e) =>
                      handleInputChange(row.id, 'AnnualINR', e.target.value)
                    }
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="text"
                    value={row.Remarks}
                    onChange={(e) =>
                      handleInputChange(row.id, 'Remarks', e.target.value)
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: 'flex', gap: '1rem' }}>
        <Button variant="contained" color="primary" onClick={addRow}>
          Add Row
        </Button>
        <Button variant="contained" color="primary" onClick={deleteLastRow}>
          Delete Row
        </Button>
      </div>
    </div>
  );
};

export default SalaryStructure;
