import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Tabs, Tab } from '@mui/material';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import { getPagesByEngagementIdRequest } from '../../../constants/requests';

const getPageLink = (pageName, engagementId, applicationId) => {
  switch (pageName) {
    case 'UNDERSTANDING':
      return `/services/engagement/${engagementId}/understanding`;
    case 'RCM':
      return `/services/engagement/${engagementId}/application/${applicationId}/rcm`;
    case 'DRL':
      return `/services/engagement/${engagementId}/application/${applicationId}/drl`;
    case 'TESTING':
      return `/services/engagement/${engagementId}/application/${applicationId}/testing`;
    case 'CSA':
      return `/services/engagement/${engagementId}/application/${applicationId}/csa`;
    case 'MLP':
      return `/services/engagement/${engagementId}/mlp`;
    case 'AIT':
      return `/services/engagement/${engagementId}/ait`;
    case 'ConvertPPT':
      return `/services/engagement/${engagementId}/convertppt`;
    default:
      return null;
  }
};

const getCurrentPageDisplayName = (pages, pageName) => {
  if (pages === null) return '';
  const page = pages.find((page) => page.pageName === pageName);
  return page ? page.displayName : '';
};

const filterPages = (pages, currentPageName) => {
  if (!pages) return [];

  const disabledPages = ['RCM', 'DRL', 'TESTING', 'CSA'];
  const restrictedPages = ['MLP', 'AIT', 'ConvertPPT'];

  if (restrictedPages.includes(currentPageName)) {
    return pages.filter((page) => !disabledPages.includes(page.pageName));
  }

  return pages;
};

function Navbar({ shouldRefresh, pageName, engagementId, applicationId }) {
  const navigate = useNavigate();
  const { showNotifications } = useNotifications();
  const { request } = useLoading();

  const [pages, setPages] = useState(null);
  const [selectedPage, setSelectedPage] = useState(pageName);

  const getPages = async () => {
    try {
      const response = await request(() =>
        getPagesByEngagementIdRequest(engagementId)
      );
      setPages(response.data.body.pages);
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  useEffect(() => {
    getPages();
  }, [shouldRefresh]);

  const handlePageChange = (event) => {
    const newPageName = event.target.textContent;
    const pageLink = getPageLink(newPageName, engagementId, applicationId);
    if (pageLink) {
      setSelectedPage(newPageName);
      navigate(pageLink);
    }
  };

  const filteredPages = filterPages(pages, pageName);

  return (
    <>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Tabs
          indicatorColor="primary"
          textColor="secondary"
          value={selectedPage}
          onChange={handlePageChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          {filteredPages.map((page) => (
            <Tab
              key={page.pageName}
              label={page.displayName}
              value={page.pageName}
            ></Tab>
          ))}
        </Tabs>
      </Box>
      <Typography sx={{ fontWeight: 'bold' }} variant="h4">
        {getCurrentPageDisplayName(pages, pageName)}
      </Typography>
    </>
  );
}

export default Navbar;
