import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MasterHeader } from '../../../components/Master/Header';
import { MasterTable } from '../../../components/Master/Table';
import { headers } from '../../../constants/EmployeeHeaders';
import axios from 'axios';
import { EMPLOYEE_URL } from '../../../constants/urls';
import { BACKEND_URL } from '../../../config';
import { useDataLayerValue } from '../../../contextAPI/DataLayer';
import { ADMINISTRATOR_ROLE_ID } from '../../../constants/AdministratorConstants';

const nonAdminFields = [
  {
    label: 'First Name',
    name: 'fname',
    display: 'required',
    type: 'text'
  },
  {
    label: 'Last Name',
    name: 'lname',
    display: 'required',
    type: 'text',
    value: 'Surname'
  },
  {
    label: 'Phone',
    name: 'phone',
    display: 'required',
    type: 'text'
  },
  {
    label: 'Email',
    name: 'email',
    display: 'required',
    type: 'text'
  },
  {
    label: 'Role',
    name: 'role',
    display: 'required',
    type: 'text'
  },
  {
    label: 'Department',
    name: 'department',
    display: 'required',
    type: 'text'
  },
  {
    label: 'Designation',
    name: 'designation',
    display: 'required',
    type: 'text'
  },
  {
    label: 'Address',
    name: 'address',
    display: 'required',
    type: 'text'
  },
  {
    label: 'City',
    name: 'city',
    display: 'required',
    type: 'text'
  },
  {
    label: 'State',
    name: 'state',
    display: 'required',
    type: 'text'
  },
  {
    label: 'Zip/PIN',
    name: 'zip',
    display: 'required',
    type: 'text'
  },
  {
    label: 'Aadhar Number',
    name: 'aadharNumber',
    display: 'required',
    type: 'text'
  },
  {
    label: 'PAN Number',
    name: 'PAN',
    display: 'required',
    type: 'text'
  },
  {
    label: 'SSN Number',
    name: 'SSN',
    display: 'required',
    type: 'text'
  },
  {
    label: 'Reporting Manager ID',
    name: 'reportingManagaer',
    display: 'optional',
    type: 'text'
  },
  {
    label: 'Reporting Manager Name',
    name: 'reportingManagaerName',
    display: 'optional',
    type: 'text'
  },
  {
    label: 'Reporting Manager Email',
    name: 'reportingManagaerEmail',
    display: 'optional',
    type: 'text'
  },
  {
    label: 'Aadhar Number',
    name: 'aadharNumber',
    display: 'required',
    type: 'text'
  },
  {
    label: 'SSN Number',
    name: 'SSN',
    display: 'required',
    type: 'text'
  },
  {
    label: 'PAN Number',
    name: 'PAN',
    display: 'required',
    type: 'text'
  },
  {
    label: 'Bank Account Number',
    name: 'bankAccountNumber',
    display: 'required',
    type: 'text'
  },
  {
    label: 'IFSC Code',
    name: 'IFSCCode',
    display: 'required',
    type: 'text'
  }
];

const adminFields = [
  {
    label: 'Date Of Joining',
    name: 'dateOfJoining',
    display: 'optional',
    type: 'text'
  },
  {
    label: 'Date Of Exit',
    name: 'dateOfExit',
    display: 'optional',
    type: 'text'
  },
  {
    label: 'CTC',
    name: 'ctc',
    display: 'optional',
    type: 'text'
  },
  {
    label: 'Gross Salary',
    name: 'grossSalary',
    display: 'optional',
    type: 'text'
  },
  {
    label: 'Base Salary',
    name: 'baseSalary',
    display: 'optional',
    type: 'text'
  },
  {
    label: 'HRA',
    name: 'HRA',
    display: 'optional',
    type: 'text'
  },
  {
    label: 'Medical Allowance',
    name: 'medicalAllowance',
    display: 'optional',
    type: 'text'
  },
  {
    label: 'Conveyance Allowance',
    name: 'conveyanceAllowance',
    display: 'optional',
    type: 'text'
  },
  {
    label: 'Special Allowance',
    name: 'specialAllowance',
    display: 'optional',
    type: 'text'
  },
  {
    label: 'PF Contribution',
    name: 'PFContribution',
    display: 'optional',
    type: 'text'
  },
  {
    label: 'ESI Contribution',
    name: 'ESIContribution',
    display: 'optional',
    type: 'text'
  },
  {
    label: 'PT Deduction',
    name: 'PTDeduction',
    display: 'optional',
    type: 'text'
  },
  {
    label: 'Total Deductions',
    name: 'totalDeductions',
    display: 'optional',
    type: 'text'
  },
  {
    label: 'Net Salary',
    name: 'netSalary',
    display: 'optional',
    type: 'text'
  }
];

export const MasterEmployee = () => {
  const [data, setData] = useState({ body: [] });
  const [updated, setUpdated] = useState(false);
  const [user] = useDataLayerValue();
  const { _id, roleId } = user;
  const { DOWNLOAD, CREATE, BULK_UPLOAD, EDIT, VIEW_REPORT } =
    user.rights.Employee;
  const navigate = useNavigate();
  if (VIEW_REPORT === false) {
    navigate('/');
  }
  const toggleUpdate = () => {
    // console.log('Toggling');
    setUpdated((prevUpdated) => !prevUpdated);
  };
  useEffect(() => {
    document.title = 'Employees';
    const getData = async () => {
      const res =
        roleId === ADMINISTRATOR_ROLE_ID
          ? await axios.get(BACKEND_URL + '/employee', {
              headers: {
                employeeid: _id
              }
            })
          : await axios.get(BACKEND_URL + '/employee/status/' + _id, {
              headers: {
                employeeid: _id
              }
            });
      if (roleId !== ADMINISTRATOR_ROLE_ID) res.data.body = [res.data.body];
      if (res.data.statusCode !== 200) return;
      setData(res.data);
    };
    getData();
  }, [updated, _id]);

  const findHeaders = (headers, data) => {
    if (data.body.length === 0) return [];
    const currHeaders = [];
    for (let i = 0; i < headers.length; i++) {
      if (headers[i].key in data.body[0]) currHeaders.push(headers[i]);
    }
    return currHeaders;
  };

  const currHeaders = findHeaders(headers, data);

  const csvReport = {
    data: data.body,
    headers: currHeaders,
    filename: 'Employees.csv'
  };

  return (
    <>
      <MasterHeader
        title="Employees"
        options={{
          importOption: BULK_UPLOAD,
          exportOption: DOWNLOAD,
          createOption: CREATE
        }}
        toggleUpdate={toggleUpdate}
        csvReport={csvReport}
        links={{
          importLink: BACKEND_URL + '/employee/upload',
          createLink: '/master/register/employee'
        }}
      />
      {roleId === ADMINISTRATOR_ROLE_ID ? (
        <MasterTable
          headers={headers}
          data={data}
          toggleUpdate={toggleUpdate}
          url={EMPLOYEE_URL}
          fields={[...nonAdminFields, ...adminFields]}
          options={{
            editOption: EDIT,
            deleteOption: false,
            makeInactiveOption: EDIT,
            makeActiveOption: EDIT,
            rightsOption: false,
            sendOfferLetter: EDIT
          }}
        />
      ) : (
        <MasterTable
          headers={headers}
          data={data}
          toggleUpdate={toggleUpdate}
          url={EMPLOYEE_URL}
          fields={nonAdminFields}
          options={{
            editOption: EDIT,
            deleteOption: false,
            makeInactiveOption: EDIT,
            makeActiveOption: EDIT,
            rightsOption: false,
            sendOfferLetter: EDIT
          }}
        />
      )}
    </>
  );
};
