import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
  Select,
  MenuItem,
  InputLabel
} from '@mui/material';
import axios from 'axios';
import { EMPLOYEE_URL } from '../../../constants/urls';
import { useNotifications } from '../../../hooks/useNotifications';
import { useLoading } from '../../../hooks/useLoading';
import SalaryStructure from './SalaryStructure';

const templates = {
  template1: [
    'Salutation',
    'firstName',
    'lastName',
    'Probation_Period',
    'Reporting_Employee',
    'Reporting_Employee_Designation',
    'email',
    'Stipend'
  ],
  template2: [
    'Salutation',
    'firstName',
    'lastName',
    'email',
    'Reporting_Employee',
    'Reporting_Employee_Designation'
  ],
  template3: ['Salutation', 'firstName', 'lastName', 'salary', 'email']
};

export const OfferLetter = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    role: '',
    salary: '',
    dateOfJoining: '',
    dateOfExit: '',
    email: '',
    Salutation: '',
    Probation_Period: '',
    Reporting_Employee: '',
    Reporting_Employee_Designation: '',
    issuedBy: '',
    Stipend: '',
    department: '',
    issueDate: '',
    newRole: '',
    newDepartment: ''
  });

  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [issuedBy, setIssuedBy] = useState('');
  const [formComplete, setFormComplete] = useState(false);
  const [requiredFields, setRequiredFields] = useState([]);
  const [buttonColor, setButtonColor] = useState('grey');
  const { showNotifications } = useNotifications();
  const { request, loading } = useLoading();
  const [salaryData, setSalaryData] = useState({});
  const [sigFile, setSigFile] = useState('');
  const [clearSalaryStructure, setClearSalaryStructure] = useState(false);
  const [issueDate, setIssueDate] = useState('');
  const [dateOfJoining, setDateOfJoining] = useState('');
  const [dateOfExit, setDateOfExit] = useState('');
  const [role, setRole] = useState('');
  const [newRole, setNewRole] = useState('');
  const [department, setDepartment] = useState('');
  const [newDepartment, setNewDepartment] = useState('');

  useEffect(() => {
    setRequiredFields(templates[selectedTemplate] || []);
  }, [selectedTemplate]);

  useEffect(() => {
    const areAllFieldsFilled = requiredFields.every(
      (fieldName) => formData[fieldName] !== ''
    );

    setFormComplete(areAllFieldsFilled && issuedBy !== '' && sigFile !== '');
    setButtonColor(
      areAllFieldsFilled && issuedBy !== '' && sigFile !== '' ? 'black' : 'grey'
    );
  }, [formData, requiredFields, issuedBy, sigFile]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileSelection = async (e) => {
    const selectedFile = e.target.files[0];
    try {
      if (selectedFile) {
        const reader = new FileReader();
        reader.onloadend = () => {
          // The result property contains the base64 encoded image
          const base64String = reader.result.split(',')[1];
          setSigFile(base64String);
        };
        // Read the image file as a data URL
        reader.readAsDataURL(selectedFile);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTemplateChange = (e) => {
    setSelectedTemplate(e.target.value);
  };

  const handleSalaryStructureChange = (salaryStructureData) => {
    setSalaryData(salaryStructureData);
  };

  const handleClearSalaryStructure = () => {
    setClearSalaryStructure(!clearSalaryStructure);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();

      Object.entries(formData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
      });

      if (Object.keys(salaryData).length > 0) {
        formDataToSend.append('salaryData', JSON.stringify(salaryData));
      }
      formDataToSend.append('selectedTemplate', selectedTemplate);
      formDataToSend.append('issuedBy', issuedBy);
      formDataToSend.append('file', sigFile);
      formDataToSend.append('issueDate', issueDate);
      formDataToSend.append('role', role);
      formDataToSend.append('newRole', newRole);
      formDataToSend.append('department', department);
      formDataToSend.append('newDepartment', newDepartment);
      formDataToSend.append('dateOfJoining', dateOfJoining);
      formDataToSend.append('dateOfExit', dateOfExit);

      const response = await request(() =>
        axios.post(EMPLOYEE_URL + '/offerLetter', formDataToSend)
      );

      if (response.data.status === 'Success') {
        showNotifications('success', 'Offer Letter mailed successfully', 5000);
      } else {
        showNotifications('error', 'Error sending Offer Letter', 5000);
      }

      const excelFileInput = document.getElementById('excelFile');
      if (excelFileInput) {
        excelFileInput.value = ''; // Reset the file input
      }

      setFormData({
        firstName: '',
        lastName: '',
        role: '',
        salary: '',
        dateOfJoining: '',
        dateOfExit: '',
        email: '',
        Salutation: '',
        Probation_Period: '',
        Reporting_Employee: '',
        Reporting_Employee_Designation: '',
        issuedBy: '',
        Stipend: '',
        department: '',
        issueDate: '',
        newRole: '',
        newDepartment: ''
      });

      // Clear salary structure fields
      setSalaryData({});
      handleClearSalaryStructure();

      setIssuedBy('');
      setRole('');
      setNewRole('');
      setDepartment('');
      setNewDepartment('');
      setDateOfExit('');
      setIssueDate('');
      setDateOfJoining('');
    } catch (error) {
      console.error('Error sending form data:', error);
    }
  };

  const handleDownload = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();

      Object.entries(formData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
      });

      if (Object.keys(salaryData).length > 0) {
        formDataToSend.append('salaryData', JSON.stringify(salaryData));
      }
      formDataToSend.append('selectedTemplate', selectedTemplate);
      formDataToSend.append('issuedBy', issuedBy);
      formDataToSend.append('file', sigFile);
      formDataToSend.append('issueDate', issueDate);
      formDataToSend.append('role', role);
      formDataToSend.append('newRole', newRole);
      formDataToSend.append('department', department);
      formDataToSend.append('newDepartment', newDepartment);
      formDataToSend.append('dateOfJoining', dateOfJoining);
      formDataToSend.append('dateOfExit', dateOfExit);

      const response = await request(() =>
        axios.post(EMPLOYEE_URL + '/downloadofferletter', formDataToSend)
      );

      const uint8Array = new Uint8Array(
        response.data.message.offerLetterBuffer.data
      );
      const blob = new Blob([uint8Array], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'OfferLetter.pdf';
      a.click();
      window.URL.revokeObjectURL(url);

      if (response.data.status === 'Success') {
        showNotifications(
          'success',
          'Offer Letter Downloaded successfully',
          5000
        );
      } else {
        showNotifications('error', 'Error Downloading Offer Letter', 5000);
      }

      const excelFileInput = document.getElementById('excelFile');
      if (excelFileInput) {
        excelFileInput.value = ''; // Reset the file input
      }

      setFormData({
        firstName: '',
        lastName: '',
        role: '',
        salary: '',
        dateOfJoining: '',
        dateOfExit: '',
        email: '',
        Salutation: '',
        Probation_Period: '',
        Reporting_Employee: '',
        Reporting_Employee_Designation: '',
        issuedBy: '',
        Stipend: '',
        department: '',
        issueDate: '',
        newRole: '',
        newDepartment: ''
      });

      // Clear salary structure fields
      setSalaryData({});
      handleClearSalaryStructure();

      // Clear field data
      setIssuedBy('');
      setRole('');
      setNewRole('');
      setDepartment('');
      setNewDepartment('');
      setDateOfExit('');
      setIssueDate('');
      setDateOfJoining('');
    } catch (error) {
      console.error('Error sending form data:', error);
    }
  };

  return (
    <Container
      style={{
        marginTop: '3rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Typography variant="h2" align="center" fontWeight={600} gutterBottom>
        Offer Letter Service
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel htmlFor="template" style={{ width: '550px' }}>
              Select Template
            </InputLabel>
            <Select
              fullWidth
              variant="outlined"
              label="Select Template"
              name="template"
              value={selectedTemplate}
              onChange={handleTemplateChange}
            >
              <MenuItem value="" disabled>
                Select a template
              </MenuItem>
              <MenuItem value="template1">Internship With FTE</MenuItem>
              <MenuItem value="template2">FTE</MenuItem>
              <MenuItem value="template3">Internship</MenuItem>
            </Select>
          </Grid>

          {selectedTemplate &&
            (selectedTemplate === 'template1' ||
              selectedTemplate === 'template2' ||
              selectedTemplate === 'template3') && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Issue Date"
                  type="date"
                  variant="outlined"
                  value={issueDate}
                  onChange={(e) => setIssueDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            )}

          {selectedTemplate &&
            (selectedTemplate === 'template1' ||
              selectedTemplate === 'template2' ||
              selectedTemplate === 'template3') && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Date of Joining"
                  type="date"
                  variant="outlined"
                  value={dateOfJoining}
                  onChange={(e) => setDateOfJoining(e.target.value)}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            )}

          {selectedTemplate && selectedTemplate === 'template3' && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Date Of Exit"
                type="date"
                variant="outlined"
                value={dateOfExit}
                onChange={(e) => setDateOfExit(e.target.value)}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          )}

          {selectedTemplate &&
            templates[selectedTemplate].map((fieldName) => (
              <Grid item xs={12} key={fieldName}>
                <TextField
                  fullWidth
                  label={fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
                  variant="outlined"
                  name={fieldName}
                  value={formData[fieldName]}
                  onChange={handleChange}
                />
              </Grid>
            ))}

          {(selectedTemplate === 'template1' ||
            selectedTemplate === 'template2' ||
            selectedTemplate === 'template3') && (
            <Grid item xs={12}>
              <InputLabel htmlFor="role">Role</InputLabel>
              <Select
                fullWidth
                variant="outlined"
                label="Select an option"
                name="role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <MenuItem value="" disabled>
                  Select Role
                </MenuItem>
                <MenuItem value="Intern">Intern</MenuItem>
                <MenuItem value="Associate">Associate</MenuItem>
                <MenuItem value="Assistant Manager">Assistant Manager</MenuItem>
                <MenuItem value="Deputy Manager">Deputy Manager</MenuItem>
                <MenuItem value="Senior Manager">Senior Manager</MenuItem>
                <MenuItem value="others">Other (Please Specify)</MenuItem>
              </Select>
            </Grid>
          )}

          {role === 'others' && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Enter New Role"
                name="additionalData"
                value={newRole}
                onChange={(e) => setNewRole(e.target.value)}
              />
            </Grid>
          )}

          {(selectedTemplate === 'template1' ||
            selectedTemplate === 'template2') && (
            <Grid item xs={12}>
              <InputLabel htmlFor="role">Department</InputLabel>
              <Select
                fullWidth
                variant="outlined"
                label="Select an option"
                name="department"
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
              >
                {/* ... */}
                <MenuItem value="" disabled>
                  Select Department
                </MenuItem>
                <MenuItem value="Operations">Operations</MenuItem>
                <MenuItem value="Risk Advisory">Risk Advisory</MenuItem>
                <MenuItem value="IT Risk Management">
                  IT Risk Management
                </MenuItem>
                <MenuItem value="Sales & Marketing">Sales & Marketing</MenuItem>
                <MenuItem value="others">Other (Please Specify)</MenuItem>
              </Select>
            </Grid>
          )}

          {department === 'others' && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Enter New Department"
                name="additionalData"
                value={newDepartment}
                onChange={(e) => setNewDepartment(e.target.value)}
              />
            </Grid>
          )}

          {(selectedTemplate === 'template1' ||
            selectedTemplate === 'template2') && (
            <SalaryStructure
              formData={formData}
              onDataChange={handleSalaryStructureChange}
              onClear={clearSalaryStructure}
            />
          )}

          {selectedTemplate &&
            (selectedTemplate === 'template1' ||
              selectedTemplate === 'template2' ||
              selectedTemplate === 'template3') && (
              <Grid item xs={12}>
                <InputLabel htmlFor="issuedBy">Issued By</InputLabel>
                <Select
                  fullWidth
                  variant="outlined"
                  label="Issued By"
                  name="issuedBy"
                  value={issuedBy}
                  onChange={(e) => setIssuedBy(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    Select Issued By
                  </MenuItem>
                  <MenuItem value="Ms. Yukti Arora,CEO">
                    Ms.Yukti Arora
                  </MenuItem>
                  <MenuItem value="Ms. Jyoti Singh,Partnner">
                    Ms.Jyoti Singh{' '}
                  </MenuItem>
                </Select>
              </Grid>
            )}
          {issuedBy && (
            <Grid item xs={12}>
              <p>Please Upload your Signature</p>
              <TextField type="file" onChange={handleFileSelection} />
            </Grid>
          )}

          {selectedTemplate && (
            <Grid
              container
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
                flexDirection: 'row'
              }}
            >
              <Grid
                item
                xs={6}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: buttonColor,
                    color: 'white',
                    fontSize: '1rem',
                    minWidth: '100px'
                  }}
                  type="submit"
                  disabled={!formComplete || loading}
                  onClick={handleDownload}
                >
                  Download Offer Letter
                </Button>
              </Grid>

              <Grid
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'end',
                  marginLeft: '30px'
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: buttonColor,
                    color: 'white',
                    fontSize: '1rem'
                  }}
                  type="submit"
                  disabled={!formComplete || loading}
                >
                  Mail Offer Letter
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
    </Container>
  );
};
