import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import { useEffect } from 'react';
import { modalStyle } from '../common/styles';
import { getFormField } from '../common/getFormField';

const getInitialFormState = (formData) => {
  const initialFormState = {};
  formData.forEach((formDataItem) => {
    const { fieldName, value, required } = formDataItem;
    initialFormState[fieldName] = { value, required, error: false };
  });
  return initialFormState;
};

/**
 * Props for CreateEditDialog
 *
 * formData: [
 * {
 * 	type: 'textField' | 'dropDown',
 *  fieldName: 'name for formFields state',
 * 	fieldLabel: 'label to show on mui text field',
 * 	dropDownOptions?: [{ label: 'Yes', value: true },],
 * 	value: 'initial state value',
 * 	required: true/false
 * },
 * ]
 * onCloseCB: callback for closing the modal,
 * refreshPageContentCB: callback of parent component to refresh its content when the modal closes
 * entityId: pass this only for Update modal,
 * entityName: name to show in modal heading and submit button,
 * getEntityByIdRequest?: Request for fetching entity by id. Pass only for Update modal,
 * updateEntityRequest?: Update enitity by id. Pass only for Update modal,
 * createEntityRequest?: Create enitity,
 * additionalPayloadParameters?: any additional payload parameters to be added
 * customUpdateCB?: custom callback function for updation.
 * customName?: to provide custom name to modal
 */

export default function CreateEditDialog({
  formData,
  onCloseCB,
  refreshPageContentCB,
  entityId,
  entityName,
  getEntityByIdRequest,
  updateEntityRequest,
  customUpdateCB,
  customName,
  createEntityRequest,
  additionalPayloadParameters
}) {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();

  const [formFields, setFormFields] = useState(getInitialFormState(formData));

  const getEntityDetails = async () => {
    try {
      const response = await request(() => getEntityByIdRequest(entityId));
      if (response.data.statusCode === 200) {
        const savedFormFields = { ...formFields };
        Object.keys(response.data.body).forEach((key) => {
          if (savedFormFields[key] !== undefined)
            savedFormFields[key].value = response.data.body[key];
        });
        setFormFields(savedFormFields);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  useEffect(() => {
    if (entityId) getEntityDetails();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormFields({
      ...formFields,
      [name]: {
        ...formFields[name],
        value
      }
    });
  };

  const handleSubmit = async () => {
    try {
      const formFieldErrors = { ...formFields };
      let hasValidationErrors = false;

      Object.keys(formFieldErrors).forEach((formField) => {
        if (formFieldErrors[formField].required) {
          if (formFieldErrors[formField].value.length === 0) {
            formFieldErrors[formField].error = true;
            hasValidationErrors = true;
          } else {
            formFieldErrors[formField].error = false;
          }
        }
      });
      if (hasValidationErrors) {
        setFormFields(formFieldErrors);
        return;
      }
      if (customUpdateCB) {
        customUpdateCB(formFields);
        return;
      }

      let payload = { ...additionalPayloadParameters };
      Object.keys(formFields).forEach((formField) => {
        payload[formField] = formFields[formField].value;
      });
      let response;
      if (entityId)
        response = await request(() => updateEntityRequest(entityId, payload));
      else response = await request(() => createEntityRequest(payload));

      if (
        response.data.statusCode === 200 ||
        response.data.statusCode === 201
      ) {
        onCloseCB();
        refreshPageContentCB();
        showNotifications('success', response.data.message, 5000);
      } else showNotifications('error', response.data.message, 5000);
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const formElements = formData.map((formDataItem) => {
    const { type, fieldName, fieldLabel, dropDownOptions } = formDataItem;
    return {
      type,
      formFieldsState: formFields,
      fieldName,
      fieldLabel,
      handleChangeCB: handleChange,
      dropDownOptions
    };
  });

  return (
    <Box sx={modalStyle}>
      <Typography variant="h5" component="h2" style={{ marginBottom: '20px' }}>
        {customName
          ? customName
          : entityId
          ? `Edit ${entityName}`
          : `Add ${entityName}`}
      </Typography>

      {formElements.map((formElement) => getFormField(formElement))}

      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          style={{ marginTop: '10px' }}
          onClick={handleSubmit}
        >
          {customName
            ? customName
            : entityId
            ? `Edit ${entityName}`
            : `Create ${entityName}`}
        </Button>
        <Button
          variant="contained"
          style={{ marginTop: '10px' }}
          onClick={onCloseCB}
        >
          Close
        </Button>
      </Box>
    </Box>
  );
}
